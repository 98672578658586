import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { apim } from "@/constants/api"
import { getConfig } from "@/constants/config"
import { formatMultiSelectFacets, getUserPersona } from "@/utils/helper"
import qs from "qs"
 
const initialState = {
  plp: {
    loaded: false,
    loading: true,
    fq: [],
    data: [],
    total: 0,
    facets: [],
    facetLabels: [],
    start: 0,
    colors: {},
    grouped: false
},
}
 

export const getProducts= createAsyncThunk("asPlp/getProducts", async({query, update, category, fls = [], isServiceParts = false}) =>{
  
 
    
    const CONFIG = await getConfig()
    
    const lwAppName = CONFIG.general?.lwAppName ?? ''
    const language = CONFIG?.internationalization?.language ?? 'en';
    const persona = getUserPersona()
    const { general: {serviceParts = ""} = {}} = CONFIG
    
    if(!query?.fq){
      query.fq = `language_s:("${language}")`
    } else {
      if(typeof query.fq === 'string' && query.fq.length > 0){
        query.fq = [query.fq, `language_s:("${language}")`]
      } else if(Array.isArray(query.fq)){
        query.fq = [...query.fq, `language_s:("${language}")`]
      }
    }

    try {
      const fl = [
        "masterSKU_s",
        "masterCustomerFacingSKU_s",
        "SKUColorSwatchFilename_ss",
        "SKUColorSwatchFilename_s",
        "ProductDescriptionProductShort_s",
        "ProductBrandName_s",
        "metaTitle_s",
        "productName_s",
        "RegionBrandName_s",
        "id",
        "productImages.url_s",
        "productImages.url_ss",
        "slug_s",
        "Product_Category",
        "displayPriceStartingAt_s",
        "Tile_Title",
        "Collapse_Title_s",
        "Collapse_Description_s",
        `priceList.${persona}.price_d`,
        `priceList.${persona}.finalPrice_d`,
        `priceList.${persona}.discountedPrice_d`,
        `priceList.${persona}.saleOffer_s`,
        `priceStartingAt.${persona}_d`,
        `discountedPriceStartingAt.${persona}_d`,
        "variantList.sku_ss",
        "variantList.sku_s",
        "SKUColorFinishName_s",
        "SKUColorFinishName_ss",
        "SKU.Details_ss",
        "Color.SKU.Details_ss",
        "Color.SKU.Details_s",
        `Color.${persona}.Details_ss`,
        "ProductExclusive_s",
        "RegionReleaseforShipment_dt",
        "RegionLaunchDate_dt",
        "productImages.labelWithUrl_ss",
        "productImages.labelWithUrl_s",
        "productImageVariantImages.labelWithUrl_ss",
        "RegionOnBackOrder_s",
        "ProductIsRetail",
        "comboSKUAvailable_b",
        "category1_s",
        "ProductBrandNameDisplay_s",
        "ctId_s",
        "ProductResource.InteractiveSVG.resourceName_ss",
        "ProductProductNo_s",
        "Non_Collapse_Title_s",
        'ProductResource.InteractiveSVG.resourceTitle_ss',
        "Brand_ss",
        "sku_s",
        "ProductDesigner_s",
        "Sale.*.Details_ss",
        ...fls
      ]
      
      const { data } = await apim.get(`/search/plp`, {
        params: {
          fl: fl.join(","),
          q: "*:*",
          ...query,
          rows: query.rows || 30,
          start: query.start || 0,
          profilename: isServiceParts ? `profile_${serviceParts}_PLP_${category}` : `profile_${lwAppName.toLowerCase()}_PLP_${category}`,
          collections: isServiceParts ? serviceParts : lwAppName.toLowerCase(),
          persona: persona
        },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "repeat", encode: false })
        },
      })
      const payload = {
        facets: data.facet_counts?.facet_fields,
        facetLabels: data.fusion?.facet_labels ?? [],
        grouped: data.fusion?.isCollapsePLP === "true" || data.fusion?.isCollapsePLP === true
      }
      
      let priceRange = payload.grouped ? data.facet_counts?.facet_ranges[`discountedPriceStartingAt.${persona}_d`] : data.facet_counts?.facet_ranges[`priceList.${persona}.finalPrice_d`]
      
      if(priceRange) {
        const gap = priceRange.gap
        // eslint-disable-next-line camelcase
        payload.facets.Price_Range = priceRange.counts.map(item => typeof(item) === "string" ? `[${parseInt(item)} TO ${parseInt(item) + gap}]` : item)
      }
      //mark multiselect
      payload.facets = formatMultiSelectFacets(data)
      // set color swatches
      payload.colors = data.fusion?.color_swatch_labels ?? {}
      
      if(data.response) {
        payload.data = data.response.docs
        payload.total = data.response.numFound
        payload.start = data.response.start
        if (data.fusion?.isCollapsePLP === "true" || data.fusion?.isCollapsePLP === true) {
          payload.grouped = true;
        }
      } else {
        payload.data = data.grouped[Object.keys(data.grouped)[0]].groups.map(doc => doc.doclist.docs[0])
        payload.total = data.grouped[Object.keys(data.grouped)[0]].groups.length
        payload.start = 0
        payload.grouped = true;
      }
      if(update){
        return { type: "GET_MORE_PRODUCTS", payload }
      }else{
        return { type: "GET_PRODUCTS", payload }
      }
    } catch (err) {
      console.error(err)
    }
  
})
export const asPlpSlice = createSlice({
  name: "asPlp",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProducts.pending, (state, action) => {
        return {
          ...state,
          plp:{
            ...state.plp,
            loading :true,
            loaded: false
          }
          
        }
      })
      .addCase(getProducts.fulfilled, (state, action) => {
      if (action?.payload?.type === "GET_MORE_PRODUCTS") {
          return {
            ...state,
            plp: {
              ...state.plp,
              data: [...state.plp.data, ...action.payload.payload.data],
              loading :false,
              loaded: true
            },
          }
        } else if (action?.payload?.type === "GET_PRODUCTS") {
          return {
            ...state,
            plp:{
              ...state.plp,
              ...action.payload.payload,
              loading :false,
              loaded: true
            }
            
          }
        }
      })
      .addCase(getProducts.rejected, (state, action) => {
        return {
          ...state,
          plp:{
            ...state.plp,
            loading :false,
            loaded: true
          }
          
        }
        
      })
  },
})
 
export const { startLoading, stopLoading } = asPlpSlice.actions
export const selectPlpState = state => state.asPlp
 
export default asPlpSlice.reducer